import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import claimProjectReportSubRouteService from '@rd-web-markets/shared/dist/services/claim_groups/claim_project_report_sub_route.service';
import generalRouteService from '@rd-web-markets/shared/dist/services/general_route.service';
import generalRouteSubRouteService from '@rd-web-markets/shared/dist/services/general_route_subroute.service';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import orderBy from 'lodash.orderby';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
const api = buildService('/claim_project_reports/{id}/select_researchers', {
  only: ['all', 'create']
});
const researcherSelect = {
  useResearcherSelect(show) {
    const [data, setData] = useState([]);
    const {
      report_id
    } = useParams();
    const getNonProjectResearchers = useErrorHandling(useCallback(async () => {
      researcherSelect.useGetResearchers(setData, report_id);
    }, [report_id]));
    const selectResearcher = useErrorHandling(useCallback(async (id, data, setTableData, tableData) => {
      const response = await this.post(report_id, {
        id: id
      });
      const newData = data.filter(d => d.id !== Number(id));
      setData([...newData]);
      setTableData([...tableData, response]);
    }, [report_id]));
    useEffect(() => {
      if (show) {
        getNonProjectResearchers();
      }
    }, [getNonProjectResearchers, show]);
    return [data, selectResearcher, setData, report_id];
  },
  async useGetResearchers(setData, selectedProjectId, searchQuery) {
    const searchParams = {};
    if (searchQuery) {
      const searchQueryTrimmed = searchQuery?.trim();
      if (!searchQueryTrimmed?.length) return;
      searchParams.search = searchQueryTrimmed;
    }
    const response = await researcherSelect.get(selectedProjectId, searchParams);
    const responseOrderedById = orderBy(response, ['first_name'], ['asc']);
    setData([...responseOrderedById]);
  },
  async get(selectedProjectId, searchParams) {
    const allNonProjectResearchers = await api.all(selectedProjectId, searchParams);
    return allNonProjectResearchers;
  },
  async post(selectedProjectId, body) {
    return await api.create(selectedProjectId, body);
  }
};
export default researcherSelect;