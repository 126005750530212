import { useCallback, useState, useEffect } from 'react';
import { api } from '@rd-web-markets/shared/dist/services/service';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
const notificationsService = {
  useFetchNotification: () => {
    const [notifications, setNotifications] = useState([]);
    const fetchNotifications = useErrorHandling(useCallback(async () => {
      const notifications = await notificationsService.get();
      setNotifications(notifications.filter(n => n.status !== 'deleted'));
    }, []));
    useEffect(() => {
      fetchNotifications();
    }, [fetchNotifications]);
    return [notifications, setNotifications];
  },
  useSetStatus: () => {
    return useErrorHandling(useCallback(async (id, status) => {
      await notificationsService.update(id, status);
    }, []));
  },
  get: () => api.get(`/my/notifications`),
  update: (id, status) => api.update(`/my/notifications/${id}`, {
    status
  })
};
export default notificationsService;