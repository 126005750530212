import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import claimProjectReportSubRouteService from '@rd-web-markets/shared/dist/services/claim_groups/claim_project_report_sub_route.service';
import generalRouteService from '@rd-web-markets/shared/dist/services/general_route.service';
import generalRouteSubRouteService from '@rd-web-markets/shared/dist/services/general_route_subroute.service';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import orderBy from 'lodash.orderby';
const staffService = {
  useImportStatus(companyId, report_id) {
    const [data, setData] = useState([]);
    const [importStatus, setImportStatus] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [intervalId, setIntervalId] = useState(null);
    const getImportStatusAndResearcherData = useErrorHandling(useCallback(async () => {
      const response = await this.getImportStatus(companyId, report_id);
      if (response === null) {
        setImportStatus({
          import_status: 'never_imported'
        });
      } else {
        setImportStatus({
          ...response
        });
      }
      if (response?.import_status !== 'pending') {
        staffService.useGetStaffCost(companyId, setData, report_id);
      }
      return response;
    }, [companyId, report_id]));
    const importTemplate = useErrorHandling(useCallback(async body => {
      const response = await this.importResearcherTemplate(companyId, body, report_id);
      setImportStatus({
        ...response
      });
    }, [companyId, report_id]));
    useEffect(() => {
      if (importStatus.import_status === 'pending' && !intervalId) {
        const currentId = setInterval(() => {
          getImportStatusAndResearcherData();
        }, 4000);
        setIntervalId(currentId);
      } else if (importStatus.import_status !== 'pending' && !!intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
      return () => {
        clearInterval(intervalId);
      };
    }, [getImportStatusAndResearcherData, importStatus.import_status, intervalId, report_id]);
    useEffect(() => {
      getImportStatusAndResearcherData();
    }, [getImportStatusAndResearcherData]);
    return [data, setData, importStatus, importTemplate, searchQuery, setSearchQuery];
  },
  async useGetStaffCost(companyId, setData, selectedProjectId) {
    let searchQuery = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    setData([]);
    const response = await staffService.getResearchers(companyId, selectedProjectId, 'researchers', searchQuery);
    const responseOrderedById = orderBy(response, ['id'], ['asc']);
    setData([...responseOrderedById]);
  },
  useExportTemplate(selectedProjectId, companyId) {
    const companyRoute = `/api/companies/${companyId}/export_researcher_template`;
    const projectRoute = `/api/claim_project_reports/${selectedProjectId}/export_researcher_template`;
    const route = selectedProjectId ? projectRoute : companyRoute;
    window.open(route);
  },
  getResearchers(companyId) {
    let selectedProjectId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    let route = arguments.length > 2 ? arguments[2] : undefined;
    let searchQuery = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
    const finalSearchQuery = `?search=${searchQuery}`;
    if (selectedProjectId) {
      return generalRouteSubRouteService.getAll(selectedProjectId, 'claim_project_reports', 'researchers', finalSearchQuery);
    } else {
      const finalRoute = `${route}${finalSearchQuery}`;
      return generalRouteService.getAll(`/companies/${companyId}/${finalRoute}`);
    }
  },
  getImportStatus(companyId, report_id) {
    return generalRouteService.getAll(`claim_project_reports/${report_id}/import_researcher_templates`);
  },
  importResearcherTemplate(companyId, body, claimProjectReportId) {
    return generalRouteService.postFormData(`/claim_project_reports/${claimProjectReportId}/import_researcher_templates`, body);
  }
};
export default staffService;